<template>
  
  <head>
		<meta name='viewport' content="width=device-width, initial-scale=1.0">
	
</head>
  <v-app>
    <v-main>


		<div id="droppedclass">
		
		
		<VCardTitle id="upg">Payout USD</VCardTitle>
		<div id="grouper">
			<VCard id="short" flat color="transparent"><img id='scos' v-if="true" v-bind:src="require('.//assets/logotwo.png')"/></VCard><VCardSubtitle id="grownf" class='text-wrap'><button @click="$router.push({path:'/'})" id='greense'>Payouts</button><button id="margin" @click="$router.push({path:'/withdrawals'})">Withdrawals</button></VCardSubtitle>
		</div>
		</div>
   
		
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import axios from 'axios';
import { VCardSubtitle } from 'vuetify/lib/components/index.mjs';


export default {
  
  name: 'App',
  god:'',

  beforeCreate(){
    var laugh = 'TRAFFIC'
    if (this.$store.state.Maintenance == true){
      if(localStorage.adminmaintenance == 'true'){

      }else{
        this.$router.push({path:'/maintenance'})
      }
      
    }else{
		

	}
    
    this.$store.commit('initializeStore')
    //console.log(this.$store.state.isAuthenticated, 'looddwdw')
    if(this.$store.state.token){
      laugh = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Token ' + this.$store.state.token 
      this.$store.state.isAuthenticated = true
    }else{
      axios.defaults.headers.common['Authorization'] = ''
      this.$store.state.isAuthenticated = false
      
      
      //this.$router.push({ path: '/login' })
      }
      //

   
    

    const fromData = {
					USER:laugh
				}
		

				axios
					.post('/api/v1/IS_MAINT/', fromData).then(response =>{
						
						//console.log(response.data.MAINTENANCE, '444')
            
            if(response.data.MAINTENANCE == true){
              if(this.$store.state.token){

                localStorage.removeItem('token')
                localStorage.removeItem('username')
                
              }
              this.$store.state.Maintenance = true
              localStorage.setItem('adminmaintenance', false  )
              this.$router.push({path:'/maintenance'})
              
            }else{
              this.$store.state.Maintenance = false
              localStorage.setItem('adminmaintenance', true)
            }

					}).catch(error => {
						if(error.response){
							//console.log(error.response.data)
							
							
							
						}
					})
		
    
  },


  data(){
		return{
			money:0,myusername:'',within:0,showboard:true,link:'',showpair:[]
			
		}
	},

 	 mounted: async function() {		
		
		


		
		
		
	},

	methods:{

		REDIRECT(where){

			if (where == 'home'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/home')
			}

			if (where == 'economies'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/economies')
			}

			if (where == 'open'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/waitlist')
			}

			if (where == 'preorder'){
				window.location.replace('https://unlimitedmoneygenerator.com/preorder/gambling-x')
			}

			if (where == 'discover'){
				window.location.replace('https://unlimitedmoneygenerator.com/worlds')
			}

			if (where == 'create'){
				window.location.replace('https://unlimitedmoneygenerator.com/create/normal')
			}
			if (where == 'deposits'){
				window.location.replace('https://unlimitedmoneygenerator.com/deposits')
			}
			if (where == 'account'){
				window.location.replace('https://unlimitedmoneygenerator.com/profile')
			}

			if (where == 'social'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/social')
			}

			if (where == 'login'){

				window.location.replace('https://unlimitedmoneygenerator.com/login')
				
			}
			},
		redacted(){
			this.within = window.innerWidth
			this.hithin = window.innerHeight
			
			

		},
		redact(){
			var self = this;
			setTimeout(function() {
				if(self.$route.name !='about' ){


				if(self.$route.name != 'abouts' ){

					if(self.$route.name != 'home'){

					
						//self.$router.push({path:'/e2024/home'})

						}
					

				}

				}
											
										}, 1000);
		},
		jumpstart(){
			window.location.replace('https://unlimitedmoneygroup.com')
		},

		GETSTATS(){
				if(this.$store.state.token){

				
				const fromData = {
					'Token':this.$store.state.token
				}

				axios
					.post('/api/v1/playerpost/', fromData).then(response =>{
						
						

						this.$store.state.user.money = response.data.UM
						
						this.myusername = response.data.username
						

						//console.log(this.username)

					}).catch(error => {
						if(error.response){
							
							
							this.$store.state.user.money = 0

							this.$store.state.user.money = 0
							
							
						}
					})
				}
			
		},

		async Logout(){

				axios
					.post('/api/v1/token/logout/')
					.then(response =>{
						
						
						//console.log('Logged Out')
					
					}).catch(error => {
						if(error.response){
							//console.log(error.response.data)
						}
					})
				axios.defaults.headers.common['Authorization'] = ''
				localStorage.removeItem('token')
				
				this.$store.commit('removeToken')
				localStorage.removeItem('username')
				localStorage.removeItem('Tutorial')
				localStorage.removeItem('id')
				this.password = ''
				this.$store.state.user.money = 0
				this.$store.state.user.username = ''
				this.myusername = ''
				this.money = 0
				localStorage.money = 0

				

				window.location.replace('https://unlimitedmoneygenerator.com/')



				},
	}
}
</script>

<style scoped>
#red{

	position: absolute;
	width:50%;
	height:80%;
	text-align: right;
	top:10%;
	right:0%;
}
#grouper{
	width:100%;
	height:auto;
	display: flex;
}
#short{
	margin-left:1.25%;
	opacity: 0;
	width:5%;
	background-color: rgb(148, 133, 133);

}
#scos{
	width:100%;

	height:auto;
}
#bold{
	font-weight: bold;;
}
#godown{color:rgb(183, 183, 183);
margin-top:1.5%;

}
#droppedclass {
	width: 100%;
	height: auto;
	right: 0%;
	top: 0%;
	position: relative;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}

#body {
            background-color: grey;
        }
#dice{
	font-size: 90%;
	margin-top:-0.20%;
}
#dicetv{
	font-size: 120%;
	margin-top:-0.20%;
}
#getup{
	margin-top:-2.6%;
}

#dicet{
	font-size: 150%;
	margin-top:-0.20%;
}
#dicety{
	font-size: 80%;
	margin-top:-0.8%;
}

#dicets{
	font-size: 150%;
	margin-top:0.1%;
}
#downer{
	font-size: 80%;
	margin-top:6.9%;
}
#downere{
	font-size: 80%;
	margin-top:-1.1%;
	z-index:60;
	position: absolute;
}
#downers{
	font-size: 120.5%;
	margin-top:4.45%;
}

#downerse{
	font-size: 120.5%;
	margin-top:.75%;
}
#bold{}
#discuptt{
	font-weight: 500;
	font-size:140%;
}
#discupttt{
	font-weight: 500;
	font-size:110%;
	top:-15%;
}
#discuptts{
	font-weight: 500;
	font-size:150%;
}
#margins{
	margin-left:5%;
	font-size:110%;
	font-weight: bold;
}
#marginssm{
	margin-left:5%;
	margin-top:2%;
	font-size:130%;
}
#marginssmm{
	margin-left:5%;
	margin-top:2%;
	font-size:100%;
}

#discb{
	position:absolute;
	width:100%;
	right:0%;
	height:100%;

	top:40%;
	padding-right:10px;
	text-align: right;
	
}
#disc {
	position: absolute;
	width: 100%;
	height: 8.5%;
	padding: 7.5px;
	border-bottom: 4px solid rgb(2, 9, 2);
	margin: 40;
	text-align: left;
	right: 0px;
	top:4.4%;
	color:rgba(47, 163, 34, 0.597);
	background-color: rgb(8, 8, 8);
	opacity: 1;
	z-index: 150;
	
}


#discup {
	position: absolute;
	width: 100%;
	height: 5.1%;
	
	border-bottom: 4px solid rgb(2, 9, 2);
	margin: 40;
	text-align: left;
	right: 0px;
	padding-top:4px;
	top: 11.75%;
	padding-left:5px;
	color:rgb(141, 141, 141);
	background-color: rgb(5, 5, 5);
	opacity: 1;
	z-index: 150;
	
}

#disce {
	position: absolute;
	width: 100%;
	height: 5%;
	


	text-align: left;
	right: 0px;
	top:0%;
	color:rgb(201, 201, 201);
	background-color: rgb(8, 8, 8);
	
	opacity: 1;
	z-index: 50;

	
}


	#bolde{font-size: 100%;}
	#bolder{font-size: 140%;}
	#boldes{font-size: 150%;}
#marginst{
	margin-left:5%;
	font-size:180%;
	margin-top:.5%;

}
#discbt{
	position:absolute;
	width:100%;
	right:2%;
	height:100%;

	top:40%;
	padding-right:10px;
	text-align: right;
	
}
#discbtt{
	position:absolute;
	width:100%;
	right:2%;
	height:100%;

	top:20%;
	font-size:40%;
	padding-right:10px;
	text-align: right;
	
}

#greense{
	font-weight: bold;
	opacity: 0;
	color:rgb(20,120,20);
	
}
#margin{
	font-weight: bold;
	opacity: 0;
	margin-left:3.5%;
}


@media only screen and (max-width:1367px){
	#droppedclass {
	width: 100%;
	height: auto;
	right: 0%;
	top: 0%;
	position: relative;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#short{
	margin-left:1%;
	width:10%;
}

#grownf{

font-size:120%;
}
}
@media only screen and (max-width:1100px) and (min-height:1200px){
	#droppedclass {
	width: 100%;
	height: auto;
	right: 0%;
	top: 0%;
	position: relative;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#short{
	margin-left:1%;
	width:10%;
}

#grownf{

font-size:120%;
}
}
@media only screen and (max-width:500px){
	#droppedclass {
	width: 100%;
	height: auto;
	right: 0%;
	top: 0%;
	position: relative;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#grownf{
	width:40%;
	font-size:150%;
}
#short{
	margin-left:1%;
	width:20%;
}
}

@media only screen and (max-width:400px){
	#grownf{
		width:45%;
		}
}

@media only screen and (max-width:768px) and (min-height:1000px){
	#droppedclass {
	width: 100%;
	height: auto;
	right: 0%;
	top: 0%;
	position: relative;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#grownf{

	font-size:120%;
}
#short{
	margin-left:1%;
	width:15%;
}

}

@media only screen and (max-width:1000px) and (max-height:600px){
	#droppedclass {
	width: 100%;
	height: auto;
	right: 0%;
	top: 0%;
	position: relative;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}

}

@media only screen and (min-width:1700px){
	#grownf{

font-size:140%;
}
#short{
margin-left:1%;
width:6%;
}	
}

</style>
