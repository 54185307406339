<template>

<html>
<head>
	<meta name='viewport' content="width=device-width, initial-scale=1.0">
  
</head>
<body>


	
    <div id="droppedclass">
		<div id="topcontainer"><VCardTitle id="godown">Daily Payouts</VCardTitle>
			<VCard id="orientation" height="400" v-if="event">
			<VCardTitle id="godown" v-if="event" class='texto'>Events</VCardTitle>
			
			<VCardSubtitle id="godown" v-if="event" class="text-wrap">Users only have to pay 1% on their winnings, until November 14th.</VCardSubtitle>
			<VCard id="banner" height='250' v-if="event">
			<VCard height="220" id="event" v-if='event && within < 1700'>
				<VCardTitle id="heightdown" v-if='event && within < 500' class="text-wrap">{{dayse}} D:{{hourse}}:{{minutese}}:{{secondse.toFixed(0)}}</VCardTitle>
				<img id='skull' v-if="true" v-bind:src="require('../assets/99_ RTP Payout Event Banner Nov 30th.jpg')"/>
				
			</VCard>
			<VCard height="250" id="event" v-if='event && within >= 1700'>
				<VCardTitle id="heightdown" v-if='event && within < 500' class="text-wrap">{{dayse}} D:{{hourse}}:{{minutese}}:{{secondse.toFixed(0)}}</VCardTitle>
				<img id='skull' v-if="true" v-bind:src="require('../assets/99_ RTP Payout Event Banner Nov 30th.jpg')"/>
				
			</VCard>
			<VCard height="220" id="eventright" v-if='event && within > 500' flat color="transparent">
				<VCardTitle id="heightdown" class="text-wrap">{{dayse}} D:{{hourse}}:{{minutese}}:{{secondse.toFixed(0)}}</VCardTitle>
			</VCard>
			</VCard>
			
		</VCard>
		</div>
		<VCardTitle id="godownr" v-if="ordering">Warning </VCardTitle>
		<VCard  id='skullr' height="50" v-if="ordering==true" class="rounded-pill"><img id="mark" v-if="true" v-bind:src="require('../assets/defec7313c3d8205a7c1b88c5fd9f82dda1b0ac4.png')"/></VCard>
		<VCard id='barlineds' v-if="ordering == false">s</VCard>
		
			<VCardSubtitle id="godownrr" v-if="ordering" class="text-wrap">Placing multiple orders in one day is considered gambling, and is not recommended. Play within your means, and do not abuse this system.</VCardSubtitle>
			<VCard id='barlinedr' v-if="ordering != false">s</VCard>
			<VCard height="600" id="mountupt" v-if="terms"  >
			<v-infinite-scroll id='all'>
			<VCardTitle id='whiter'>Terms</VCardTitle>

				<VCardSubtitle class="text-wrap" id='white'>The Terms of Service ("Terms") outlined herein constitute a legal agreement between Payout USD. (collectively referred to as “Payout USD”, “the Company”, “we“, “us” or “our” in this Terms of Service document) and yourself (collectively referred to as "you" or “the user” hereafter). By participating for a payout, using Payout USD Services, in any way, clicking "I accept," accessing applications integrated with Payout USD technology, or taking part in any Experiences or Services (collectively, the "Experiences'' and “Services”, used interchangeably throughout, encompassing all activities within Payout USD's platform, you confirm that you have reviewed these Terms, any additional obligations, or Rules associated with each Experience, and agree to abide by these Terms. Your use of any of Payout USD's Services indicate your acceptance of these Terms. If you do not agree to these Terms, refrain from clicking "I accept", abstain from participating in a payout, and avoid using Payout USD's Services. Non-acceptance of these Terms restricts your ability to participate in all associated Payout USD's Experiences.</VCardSubtitle>
				
				<VCardTitle id="whiter">Gambling is strictly prohibited</VCardTitle>
				<VCardSubtitle id="whiter">Only one order per day is allowed per person. Users are prohibited from placing multiple orders per day.</VCardSubtitle>
				<VCardTitle id="whiter">Payouts are random and not guaranteed</VCardTitle>
				<VCardSubtitle id="whiter" class="text-wrap">At no point in time, in any payout, is a person guaranteed to win. By participating in a payout, you agree that you are not guaranteed to receive a payout. Payouts are random, and frequent but at no time in any payout, will every participant win. Each payout is subject to change, and recalibrate based on how many participants there are. Payouts are completely random, and the owners of Payout USD, have nothing to do with each payout, as each winning, is determined by math and probability.</VCardSubtitle>
				<VCardTitle id="whiter">Cheating, fraud, and abuse</VCardTitle>
				<VCardSubtitle id='whiter' class="text-wrap">By accessing or participating in Payout USD and using the Platform or Services, you agree not to engage in any activity that disrupts or attempts to disrupt the Services. This includes using unfair methods while participating in Services or using the Payout USD Software, such as:</VCardSubtitle>
				<VCardSubtitle id="whiterd" class="text-wrap">1. Using unauthorized software or hardware to unfairly impact the winning ratio and other user experiences.</VCardSubtitle>
				
				<VCardSubtitle id="whiterbd" class="text-wrap">Engaging in such activities, collectively termed as 'Abuse,' will result in immediate sanctions at our discretion.</VCardSubtitle>
				<VCardSubtitle id="whiterbd" class="text-wrap">Any attempt to gain unauthorized access to systems or any other user’s account, interfere with procedures or performance of Services, Platform, or the Website, or deliberately damage or undermine the Platform or Services is subject to civil and/or criminal prosecution.</VCardSubtitle>
				<VCardTitle id="whiter">There is a {{100-rtp	}}% fee on all winnings</VCardTitle>
			</v-infinite-scroll>
		</VCard>
		<VCard height="510" id="mountupt" v-if="ordering && view=='purchase' && within > 500" >
			<VCardTitle id='whiter'>Payment</VCardTitle>
			<div id="grouper" v-if='within > 500'>
			
			<VCard  id='qrcode' ><img id="qr" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + paymentaddress" /></VCard>
			

				<VCard  id='paymentinfo'  >
					<VCardTitle id="whiter">Order Details</VCardTitle>
					<VCardSubtitle id="whitertt">{{ orders }} Order(s)</VCardSubtitle>
					<VCard id="usd">
				
					<v-text-field id='not' input="Text" v-model="orderemail"></v-text-field>
					</VCard>
			<VCard id="usder">
				<div id="grouper">
			<div>
				<VCardSubtitle>Send</VCardSubtitle>
				<VCardTitle  id="whiter">{{paymentamountbtc}} BTC</VCardTitle>
			</div>
			
			

			
			

			</div>
				
				
				<VCardTitle  id="whiter">To</VCardTitle>
				<v-text-field id='not' label="Payment Address" v-model="paymentaddress"></v-text-field>

				
			</VCard>

			
			
			
			

			
			</VCard>
			<div>
			<VCardTitle  id="whiter">Time Left</VCardTitle>
			<VCardSubtitle  id="whiter">{{purchasetime}}</VCardSubtitle>
		</div>
				

			</div>

			<div  v-if='within <= 500'>
			<VCard  id='qrcode' ><img id="qr" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + paymentaddress" /></VCard>
				

				<VCard  id='paymentinfo'  >
					<VCardTitle id="whiter">Order Details</VCardTitle>
					<VCardSubtitle id="whitertt">{{ orders }} Order(s)</VCardSubtitle>
					<VCard id="usd">
				    
					<v-text-field id='not' input="Text" v-model="orderemail"></v-text-field>
					</VCard>
			<VCard id="usder">
				<v-text-field id='not' label="Payment Address" v-model="paymentaddress"></v-text-field>
			</VCard>
			<div id="grouper">
			<div>
			<VCardTitle  id="whiter">Amount in BTC</VCardTitle>
			<VCardSubtitle  id="whiter">{{paymentamountbtc}} BTC</VCardSubtitle>
			</div>

			<div id="margins">
			<VCardTitle  id="whiter">Time Left</VCardTitle>
			<VCardSubtitle  id="whiter">{{purchasetime}}</VCardSubtitle>
			</div>
			

			</div>

			

			<VCardSubtitle id='godowni'>Your order will be automatically completed once the transaction is confirmed.</VCardSubtitle>
			</VCard>

			
				

			</div>
			
			<VCard id="payoutsbbtt" height="55" @click="delete_session"><VCardTitle id="black">Cancel</VCardTitle></VCard>
			
		</VCard>

		<VCard  id="mountupt"  v-if="ordering && view=='purchase' && within <= 500" >
			<VCardTitle id='whiter'>Payment</VCardTitle>
			<div id="grouper" v-if='within > 500'>
			<VCard  id='qrcode' ><img id="qr" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + paymentaddress" /></VCard>
				

				<VCard  id='paymentinfo'  >
					<VCardTitle id="whiter">Order Details</VCardTitle>
					<VCardSubtitle id="whitertt">{{ orders }} Order(s)</VCardSubtitle>
					<VCard id="usd">
				
					<v-text-field id='not' input="Text" v-model="orderemail"></v-text-field>
					</VCard>
			<VCard id="usder">
				<v-text-field id='not' label="Payment Address" v-model="paymentaddress"></v-text-field>
			</VCard>
			<div id="grouper">
			<div>
			<VCardTitle  id="whiter">Amount in BTC</VCardTitle>
			<VCardSubtitle  id="whiter">{{paymentamountbtc}} BTC</VCardSubtitle>
			</div>

			<div id="margins">
			<VCardTitle  id="whiter">Time Left</VCardTitle>
			<VCardSubtitle  id="whiter">{{purchasetime}}</VCardSubtitle>
			</div>
			

			</div>

			

			<VCardSubtitle id='godowni'>Your order will be automatically completed once the transaction is confirmed.</VCardSubtitle>

			<VCard id="payoutsbbtt" height="55" @click="delete_session"><VCardTitle id="black">Cancel</VCardTitle></VCard>
			</VCard>

			
				

			</div>

			<div  v-if='within <= 500'>
			<VCard  id='qrcode' ><img id="qr" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + paymentaddress" /></VCard>
				

				<VCard  id='paymentinfo'  >
					<VCardTitle id="whiter">Order Details</VCardTitle>
					<VCardSubtitle id="whitertt">{{ orders }} Order(s)</VCardSubtitle>
					<VCard id="usd">
				
					<v-text-field id='not' input="Text" v-model="orderemail"></v-text-field>
					</VCard>
			<VCard id="usder">
				<VCardSubtitle>Send</VCardSubtitle>
				<VCardTitle  id="whiter">{{paymentamountbtc}} BTC</VCardTitle>

				<VCardTitle  id="whiter">To</VCardTitle>
				<v-text-field id='not' label="Payment Address" v-model="paymentaddress"></v-text-field>
			</VCard>

			<div >
			<VCardTitle  id="whiter">Time Left</VCardTitle>
			<VCardSubtitle  id="whiter">{{purchasetime}}</VCardSubtitle>
			</div>
			<div id="grouper">
			<div>
			
			<VCardSubtitle  id="whiter"></VCardSubtitle>
			</div>

			
			

			</div>

			

			<VCardSubtitle id='godowni'>Your order will be automatically completed once the transaction is confirmed.</VCardSubtitle>
			</VCard>

			
				

			</div>
			<VCardSubtitle id='godownw' class="text-wrap">Your order will be automatically completed once the transaction is confirmed.</VCardSubtitle>
			<VCard id="payoutsbbtt" height="55" @click="delete_session" v-if="orderconfirmed == false"><VCardTitle id="black">Cancel</VCardTitle></VCard>
			
		</VCard>
		<VCard height="240" id="mountup" v-if="ordering"  >
			<VCardTitle id='greenddd'>Order Details</VCardTitle>
			<VCard id='barlineddd' >s</VCard>
			<VCardSubtitle id='whiterd'>Email</VCardSubtitle>
			
			<div id="grouper" v-if="placement == 'orderdetails'">
			<VCard id="usd" height='50' class="rounded-pill" v-if='within > 500'>
				
				<form @submit.prevent="GRAB_KEY" >
				<v-text-field input="Text"  label="Order Email" v-model="email"></v-text-field>
				
				</form>
				
				
				
				
			</VCard>
			<VCard id="left" class="rounded-pill" height='60' v-if='within > 500' >
				<v-text-field  input="Text" type='number' min = '1' max ='999' label="Orders" v-on:keyup="updateo" v-model="orderamount"></v-text-field>
			</VCard>

			<div id='widths' v-if="within < 500">
				<VCard id="usd" height='50' class="rounded-pill" v-if='within <= 500'>
				
				<form @submit.prevent="GRAB_KEY" >
				<v-text-field input="Text"  label="Order Email" v-model="email"></v-text-field>
				
				</form>
				
				
				
				
			</VCard>
			<VCard id="left" class="rounded-pill" height='60' v-if='within <= 500' >
				<v-text-field  input="Text" type='number' min = '1' max ='999' label="Orders" v-on:keyup="updateo" v-model="orderamount"></v-text-field>
			</VCard>
			</div>

			<VCardTitle id='white' class='text-wrap' v-if="controlerror.length > 1" >{{controlerror}}</VCardTitle>
			<VCard id="payoutsbbtt" class="rounded-pill" height="55" @click="GRAB_KEY" v-if="placement == 'orderdetails' && view != 'purchase' && loading != true"><VCardTitle id="black" >Continue</VCardTitle></VCard>
			<v-progress-circular model-value="10" color="green" :width="5" v-if="loading && ordering == true && view !='purchase'"></v-progress-circular>
			<VCard id="payoutsbbttrr" class="rounded-pill" height="55" @click="canceltrans" v-if="placement == 'orderdetails' && view == 'purchase'"><VCardTitle id="black" >Close</VCardTitle></VCard>
			</div>
		</VCard>
		<VCard id='allaround' height="70">
			<div id="grouper">
			<VCard id="button" class="ghost"  @click="ordering = !ordering" height="40"><VCardTitle id='blackl'>Place Order</VCardTitle></VCard>
			
			<VCard id="button" class="ghost" @click="$router.push({path:'/withdrawals'})" height="40"><VCardTitle id='blackl'>Withdrawals</VCardTitle></VCard>
			<VCard id="button" class="ghost" @click="terms = !terms" height="40"><VCardTitle id='blackl'>Terms</VCardTitle></VCard>
			
		</div>
		</VCard>
		<VCard id='allaround' height="900">
		<VCardTitle id="godowngreen">Payouts</VCardTitle>

		<VCard id='barline' >s</VCard>
		<VCard height="780" id="mount" >
			<VCard id='allaroundc' height="50" v-if='within < 1700'>
				<img id='scosf' v-if="true" v-bind:src="require('../assets/logotwo.png')"/>	
			<div id="grouper">
			<div id="grouperw">
			<VCardTitle id='whiter'>Price</VCardTitle>
			<VCardTitle id='whiter'>5$</VCardTitle>
			
			</div>
			<VCard height="50" id="payoutsbbt" v-if="false" @click="ordering = true"><VCardTitle id="black">Place An Order</VCardTitle></VCard>
			</div>
		</VCard>

		<VCard id='allaroundc' height="70" v-if='within >= 1700'>
				<img id='scosf' v-if="true" v-bind:src="require('../assets/logotwo.png')"/>	
			<div id="grouper">
			<div id="grouperw">
			<VCardTitle id='whiter'><label  id="scaler">Price</label></VCardTitle>
			<VCardTitle id='whiter'><label id="scaler">5$</label></VCardTitle>
			
			</div>
			<VCard height="50" id="payoutsbbt" v-if="false" @click="ordering = true"><VCardTitle id="black">Place An Order</VCardTitle></VCard>
			</div>
		</VCard>
		<VCard id='barlined' >s</VCard>
			<VCard height="750" id="ted">

				
			
			<img id='scoss' v-if="true" v-bind:src="require('../assets/francisco.jpg')"/>
			<div id="leftbox">
				<VCard id="payoutbox" height="80" v-if="within < 1700">
				<VCardTitle id="whiter" >Payout Information</VCardTitle>
				<VCardSubtitle id="whiter" >Information for the Next Payout</VCardSubtitle>
				</VCard>

				<VCard id="payoutbox" height="120" v-if="within > 1700">
				<VCardTitle id="whiter" >Payout Information</VCardTitle>
				<VCardSubtitle id="whiter" >Information for the Next Payout</VCardSubtitle>
				</VCard>
			
				<div id='grouper'>
				<VCard height="30" id="cashr" class="rounded-pill" v-if='within > 500'>
					<div id="grouper">
					<VCard id="saintsrowle" class="rounded-pill"><VCardSubtitle id="greendarkdd">RTP</VCardSubtitle></VCard><VCard id="saintsrowled"><VCardSubtitle id="white"></VCardSubtitle></VCard>
					</div>
				</VCard>

				<VCard height="40" id="cashr" v-if='within <= 500' class="rounded-pill">
					<div id="grouper">
					<VCard id="saintsrowle" height="30"  class="rounded-pill"><VCardSubtitle id="greendarkdd">RTP</VCardSubtitle></VCard><VCard id="saintsrowled"><VCardSubtitle id="white"></VCardSubtitle></VCard>
					</div>
				</VCard>
				<VCardTitle>{{ rtp }} %</VCardTitle>
			</div>
				
				<VCardTitle id='greendark'>Cash</VCardTitle>
				
				
				<VCardSubtitle id='godown'>Available</VCardSubtitle>
				
				<VCardTitle id="heightdownd" class="text-wrap">${{Intl.NumberFormat().format(market_cap*((rtp)/100))}} USD</VCardTitle>
				<div id="grouper" v-if="false">
					
					<VCard height="50" id="cash"><VCardTitle id="green">${{Intl.NumberFormat().format(market_cap*((rtp)/100))}}  USD</VCardTitle></VCard><VCard height="50" id="cashavailable"></VCard>
				</div>
				<VCardTitle id="width">Estimated Payout</VCardTitle>
				<VCard height="50" id="saint" v-if="within < 1700" ><VCard id="saintsrowl"><VCardTitle id="white">${{ Intl.NumberFormat().format((market_cap/est_payouts))*((rtp)/100).toFixed(1) }}</VCardTitle></VCard><VCard id="saintsrow"><VCardTitle id="white">{{Intl.NumberFormat().format(est_payouts)}} Payout(s)</VCardTitle></VCard></VCard>
				<VCard height="60" id="saint" v-if="within >= 1700"><VCard id="saintsrowl"><VCardTitle id="white">${{ Intl.NumberFormat().format((market_cap/est_payouts))*((rtp)/100).toFixed(1) }}</VCardTitle></VCard><VCard id="saintsrow"><VCardTitle id="white">{{Intl.NumberFormat().format(est_payouts)}} Payout(s)</VCardTitle></VCard></VCard>
				<VCardTitle id="width">Chance of Winning</VCardTitle>
				<div id="grouper">
					
					<VCard height="25" id="luck" class="rounded-pill"><VCardSubtitle id="green"></VCardSubtitle></VCard><VCard height="25" id="badluck" class="rounded-pill"></VCard> <VCardSubtitle v-if="market_cap <= 5" id="scale">{{(((1/((market_cap+5)/5))*100).toFixed(2)*est_payouts)}}%</VCardSubtitle><VCardSubtitle v-if="market_cap > 5" id="scale">{{(((1/((market_cap)/5))*100)*est_payouts).toFixed(2)}}%</VCardSubtitle>
				</div>
				<VCard height="50" id="payoutsbbt"  v-if='false' @click="ordering = true"><VCardTitle id="black">Place An Order</VCardTitle></VCard>

				<div id="rightbox" v-if="within <= 500">

<VCardSubtitle id="bolder" v-if="hours <= 24">Time Until Next Payout</VCardSubtitle>
<VCardSubtitle id="bolder" v-if="hours > 24">Time Until First Payout</VCardSubtitle>
<VCardTitle id="height">{{hours}}:{{minutes}}:{{seconds.toFixed(0)}}</VCardTitle>
</div>
			</div>

			<div id="rightbox" v-if="within > 500">

			<VCardSubtitle id="bolder" v-if="hours <= 24">Time Until Next Payout</VCardSubtitle>
			<VCardSubtitle id="bolder" v-if="hours > 24">Time Until First Payout</VCardSubtitle>
			<VCardTitle id="height">{{hours}}:{{minutes}}:{{seconds.toFixed(0)}}</VCardTitle>
			</div>
		</VCard>
					
		</VCard>
	</VCard>
		<VCard id="allaround" height="50"></VCard>
		<VCard height="330" id="mountupinvoice" v-if="view=='invoice'" @click="view='main'">
		<VCard height="300" id="mountupinvoicea" v-if="true" >
			<VCardTitle id='whiter'>Invoice</VCardTitle>
			<VCardSubtitle id='green'>Order Placed</VCardSubtitle>
			<img id='scosff' v-if="true" v-bind:src="require('../assets/d4c0349a5fa1f9973f7d171dffb34ba95feed1de.png')"/>	
			<img id='scosef' v-if="true" v-bind:src="require('../assets/glitch-effects-pack-01_8c3c5c89-88a8-44e6-bb7d-74585e2134bc_1250x.jpg')"/>	
			<div id="grouper">
			<VCard id="usdi" height="50">
				
				<v-text-field input="Text" v-model="invoiceemail"></v-text-field>
				
				
			</VCard>

			
			</div>

			<div id="grouperd">
			<VCard  id='qrcode' ><img id="qr" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + invoiceadd" /></VCard>
				

				<VCard  id='paymentinfo' height="130" >
					<VCardTitle id="whiter">Order Details</VCardTitle>
					
			<VCard id="usderi" flat height="100">
				<div id="grouper" class="note">
				<v-text-field  label="Payment Address"  v-if='within > 500' v-model="invoiceadd"></v-text-field>
				<div id="margin">
				<VCardTitle  id="whiter" v-if="within > 500">{{Number(invoicepay)}} ORDER(S)</VCardTitle>
				<VCardTitle  id="whiter" v-if="within <= 500">{{Number(invoicepay)}} ORDER(S)</VCardTitle>
			
				</div>
					
			</div>
			</VCard>

			
			
			
			

			

			<VCardSubtitle id='godowni'>Your order will be automatically completed once the transaction is confirmed.</VCardSubtitle>
			</VCard>

			
				

			</div>

			
		</VCard>
	</VCard>
	<VCardTitle id="red" v-if="errorinv">Error</VCardTitle>
	<VCard  id='skullrr' height="50" v-if="errorinv" >
		<img id='scoseff' v-if="true" v-bind:src="require('../assets/glitch-effects-pack-01_8c3c5c89-88a8-44e6-bb7d-74585e2134bc_1250x.jpg')"/>	
		<img id="marks" v-if="true" v-bind:src="require('../assets/54cb00cfa2c6c2550360a533d50392c16a954e2f.png')"/></VCard>
	<VCardSubtitle id='redr' v-if="errorinv">Order not found..</VCardSubtitle>
	
		<VCard id="allaroundg" height="100" v-if="within < 1700"  >
			<div id="goright">
				<img id='scossb' v-if="true" v-bind:src="require('../assets/f88ec0d89f743c6b6c4f52c06767922bd6acd87d.png')"/>
			</div>
			<VCardTitle id="scoob">Order</VCardTitle>
			<VCard id="usdr" class="rounded-pill"  height="50">
				<form @submit.prevent="GRAB_INVOICE(checkorderemail)">
				<v-text-field label="Order Email" v-model="checkorderemail"></v-text-field>
			</form>
				
			</VCard>
			<VCard height="50" id="payoutsbbtc" class="rounded-pill" @click="GRAB_INVOICE(checkorderemail)"><VCardTitle id="black">Check Order</VCardTitle></VCard>
		</VCard>

		<VCard id="allaroundg" height="150" v-if="within >= 1700"  >
			<div id="goright">
				<img id='scossb' v-if="true" v-bind:src="require('../assets/f88ec0d89f743c6b6c4f52c06767922bd6acd87d.png')"/>
			</div>
			<VCardTitle id="scoob">Order</VCardTitle>
			<VCard id="usdr" class="rounded-pill"  height="50">
				<form @submit.prevent="GRAB_INVOICE(checkorderemail)">
				<v-text-field label="Order Email" v-model="checkorderemail"></v-text-field>
			</form>
				
			</VCard>
			<VCard height="50" id="payoutsbbtc" class="rounded-pill" @click="GRAB_INVOICE(checkorderemail)"><VCardTitle id="black">Check Order</VCardTitle></VCard>
		</VCard>
		
		
		
		<VCardTitle id="whiterd">For Support</VCardTitle>
		<VCardSubtitle id="whiter">Email</VCardSubtitle>
		<VCard id='supporte' class="rounded-pill" ><VCardSubtitle id="whiterbd" >Support@Payoutusd.com</VCardSubtitle></VCard>
		

		<VCardSubtitle id="whiterbd">Visit</VCardSubtitle>
		<div id="grouper">
		<VCard height="40" id="glow" flat  @click="discord">
		<img id="scosss" v-bind:src="require('../assets/DiscordLogo.png')"/>
		</VCard>
		</div>
		
		
	

	
		
			
	
    </div>
	<div id="droppedclassxx" ></div>
	
	<div id="droppedclassx" ></div>


	
  

    <div id="secondrop">
    </div>
	
	


					
					
					

				


		


           


	<!--/<router-link to="/generator" id="isovertwo">Namekeee</router-link>--->


     
		
        

    </body>
    </html>
	
</template>

<script>
var x = 0
var y = 0
var teeths = false
var forgot = false
document.onmousemove = (ev) =>{
	//

	//console.log(ev.target)


	

}
import axios from 'axios'
import { withScopeId } from 'vue'
import { VCard, VCardSubtitle, VCardTitle } from 'vuetify/lib/components/index.mjs'

export default{

	data(){
		return{
			view:'main',email:'',bitcoinaddress:'',ccerror:false,controlerror:'',orderemail:'',paymentamountbtc:'',paymentaddress:'',
			invoiceadd:'', invoicepay: '', invoiceemail: '',timer:0,within:0,hithin:0,order:false,market_cap: 0, est_payouts: 0,int:[],
			checkorderemail:'',orderemail:'',inter:[],inters:[],days:0,hours:0,seconds:0,rtp:99,event:true,ordering:false,interse:[],voide:'',timere:2074000,
			dayse:0,hourse:0,secondse:0, terms:false,placement:'orderdetails',purchasetime:0,orderconfirmed:false,checkorderemail:'',loading:false,
			errorinv:false,orderamount:1,orders:0,sessionemail:''
								
								
			
		}
	},

	mounted: async function() {		


		if(!this.$store.state.isAuthenticated){
			//this.$router.push({path:'/e2024/home'})
		}else{

		}

		this.within = window.innerWidth
		this.hithin = window.innerHeight

		setInterval(this.timergo, 1000)
		setInterval(this.UPDATER, 1000)

		this.getestimates()

		this.int = setInterval(this.getestimates, 5000)

		setInterval(this.decrease, 1000)
		this.decreased()
		setInterval(this.decreased, 1000)
		this.inter = setInterval(this.showpar, 200);

		this.inters = setInterval(this.showpars, 100);

		this.interse = setInterval(this.showpairs, 100);
		this.luck()
		setInterval(this.luck, 25000)

		
	
		//window.onbeforeunload = this.clearint()

	},

	methods:{
		updateo(){

			if (this.orderamount < 1){
				this.orderamount = 1
			}

			if (this.orderamount > 999){
				this.orderamount = 999
			}

		},
		luck(){
			

			document.getElementById('luck').style.width = (((1/((this.market_cap+5)/5))*100).toFixed(2)*this.est_payouts) + '%'

			


		},

		discord(){
			window.location.assign('https://discord.gg/axzKWu6fAh')
		},

		canceltrans(){
			this.ordering = false
			this.loading = false
			this.view = 'main'
		},
		
		click(){
			this.ordering = false
			var find = document.getElementsByClassName('ghost')

			console.log(find[0].style)

		},

		decreased(){
			this.timere = this.timere - 1
			var dayind = 0
			var hourind = 0
			this.dayse = this.timere/86400

			if (true){

			

			dayind = this.dayse - Math.floor(this.dayse)
			
			dayind = (dayind*86400)/3600

			this.dayse = Math.floor(this.dayse)

			this.hourse = dayind

			var hourind = this.hourse - Math.floor(this.hourse)

			hourind = (hourind*3600)/60

			this.minutese = hourind

			this.hourse = Math.floor(this.hourse)

			var secondind = this.minutese - Math.floor(this.minutese)

			this.minutese = Math.floor(this.minutese)
			
			secondind = (secondind*60)
			
			

			this.secondse = secondind



			}
		},

		decrease(){
			this.timer = this.timer - 1
			var dayind = 0
			var hourind = 0
			this.days = this.timer

			if (true){

			

			dayind = this.days - Math.floor(this.days)
			
			dayind = (this.timer)/3600

			this.days = Math.floor(this.days)

			this.hours = dayind

			var hourind = this.hours - Math.floor(this.hours)

			hourind = (hourind*3600)/60

			this.minutes = hourind

			this.hours = Math.floor(this.hours)

			var secondind = this.minutes - Math.floor(this.minutes)

			this.minutes = Math.floor(this.minutes)
			
			secondind = (secondind*60)
			
			

			this.seconds = secondind



			}
		},

		
		grow(interval) { 

			
						
								

			var body = document.getElementById("short"); 

			//console.log(body.style.opacity, 'fade')
			if (Number(body.style.opacity) < 1) { 

				body.style.opacity = Number(body.style.opacity) + 0.1; 
				
			} else { 
				clearInterval(this.inter); 
			}

			},

		showpar(interval) { 

			
						
								

			var body = document.getElementById("short"); 

			//console.log(body.style.opacity, 'fade')
			if (Number(body.style.opacity) < 1) { 

				body.style.opacity = Number(body.style.opacity) + 0.1; 
				
			} else { 
				clearInterval(this.inter); 
			}

			},


			showpars(interval) { 
			
			
						
											

			var body = document.getElementById("orientation"); 
			var body = document.getElementById("orientation"); 

			var lead = document.getElementById('allaroundg')
			//console.log(body.style.opacity, 'fade')
			if (Number(body.style.opacity) < 1) { 

				body.style.opacity = Number(body.style.opacity) + 0.1; 
				lead.style.opacity = Number(body.style.opacity) + 0.1; 
				
			} else { 
				clearInterval(this.inters); 
			}
			
			if (true) { 
			

			this.voide =  Number(body.style.marginTop.replace('%', '')) + 0.1

			
			body.style.marginTop = this.voide + '%'
			console.log(body.style.marginTop.replace('%', ''))
			//body.style.marginTop = Number(body.style.marginTop) - 0.2 +'%'; 

			} else { 
			//clearInterval(this.inters); 
			}

			},

			showpairs(interval) { 

			var texts = document.getElementsByClassName('texto')
			
			var ghosts = document.getElementsByClassName('ghost')
			var get = document.getElementById('greense')										
			var gets = document.getElementById('margin')	
			var body = document.getElementById("mount"); 

			//console.log(body.style.opacity, 'fade')
			if (Number(body.style.opacity) < 1) { 

				body.style.opacity = Number(body.style.opacity) + 0.2; 

				texts[0].style.opacity = Number(texts[0].style.opacity) + 0.1; 
				
			} else { 
				//
			}

			if (Number(ghosts[0].style.opacity) < 1) { 

				ghosts[0].style.opacity = Number(ghosts[0].style.opacity) + 0.3; 
				get.style.opacity = Number(get.style.opacity) + 0.3; 

				

			} else { 

				if (Number(ghosts[1].style.opacity) < 1) { 
					ghosts[1].style.opacity = Number(ghosts[1].style.opacity) + 0.3; 
					gets.style.opacity = Number(gets.style.opacity) + 0.3; 
				}else{

					if (Number(ghosts[2].style.opacity) < 1) { 
					ghosts[2].style.opacity = Number(ghosts[2].style.opacity) + 0.3; 
				}else{
					
					clearInterval(this.interse); 
				}

				}


				
				}


			},



		UPDATER(){

		

			this.within = window.innerWidth
			this.hithin = window.innerHeight
			},


		timergo(){
			if (this.timer >= 1){
				//this.timer = this.timer - 1
			}else{
				this.PAYOUTSERVERTIMER()
			}
		},
		check_order(){
			const fromData = {
						'orderemail':this.orderemail.toLowerCase(),
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/check_order/', fromData).then(response =>{
							
							this.order = true
							console.log(response.data)
							if (response.data == 'Order Successfully Placed.'){
								this.checkorderemail = this.orderemail
							}else{
								this.checkorderemail = 'Error'
							}
								
							
						}).catch(error => {
							if(error.response){

								
								
							}
						})
		},
		PAYOUTSERVERTIMER(){
			const fromData = {
						'session':'',
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/PAYOUTSERVERTIMER/', fromData).then(response =>{
							
							this.timer = response.data
								
							
						}).catch(error => {
							if(error.response){

								
								
							}
						})
		},

		getestimates(){
			const fromData = {
						'session':'',
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/getestimates/', fromData).then(response =>{
							
							this.market_cap = response.data.MARKET_CAP
							this.est_payouts = response.data.EST_PAYOUTS

							this.timere = response.data.EVENT_TIMER

							if (this.timere <= 0){
								this.event = false
							}

								
							
						}).catch(error => {
							if(error.response){

								
								
							}
						})
		},



		GRAB_INVOICE(session){
			const fromData = {
						'session':session.toLowerCase(),
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/GRAB_INVOICE/', fromData).then(response =>{
							
								
								
								window.scrollTo(0, document.body.scrollHeight);
								this.invoiceadd = response.data.address
								this.invoicepay = response.data.orders
								this.invoiceemail = response.data.email
								this.view = 'invoice'
						}).catch(error => {
							if(error.response){

								this.errorinv = true

								var self = this;

								setTimeout(function() {
									self.errorinv = false

								}, 5000);


								
							}
						})
		},
		cerror(){
			this.ccerror = true
			var self = this;

								setTimeout(function() {
									self.ccerror = false

								}, 2500);
		},

		delete_session(session){
			const fromData = {
						'session':this.email,
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/delete_trans/', fromData).then(response =>{


							this.canceltrans()
							
								
									

									

							
						}).catch(error => {
							if(error.response){

								
								
							}
						})
		},
		keep_grab_session(session){
			const fromData = {
						'session':session,
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/KEEP_GRAB_SESSION/', fromData).then(response =>{
							
							
								
									
									console.log(response.data)
									if (response.data == 'Order Placed'){
										this.orderconfirmed = true
										this.GRAB_INVOICE(this.sessionemail)
									}

									this.orderemail = response.data.email

									this.paymentamountbtc = response.data.costinbtc.toFixed(8)

									this.paymentaddress = response.data.address

									this.purchasetime = response.data.timer

									this.orders = response.data.orders

									

									if (response.data != 'Order Placed'){

										if (this.view == 'purchase'){
											var self = this;
											setTimeout(function() {
												self.keep_grab_session(session)
												//self.anerrormessage = ''
												//self.$router.push({path:'/preorder/checkout-session/'+response.data.Session})

											}, 5000);
										}


										if (response.data == 'Bad Order'){
											this.view = 'main'
										}
									
									}
							
							
						}).catch(error => {
							if(error.response){

								
								
							}
						})
		},
		grab_session(session){
			const fromData = {
						'session':session,
						
						
						
						
						
						

					}

					axios
						.post('/api/v1/GRAB_SESSION/', fromData).then(response =>{
							
							if (response.data.Redirect == true){
								
									this.view = 'purchase'

									this.sessionemail = session

									this.keep_grab_session(session)
							}else{
								var self = this;
								setTimeout(function() {
									self.grab_session(session)
									//self.anerrormessage = ''
									//self.$router.push({path:'/preorder/checkout-session/'+response.data.Session})

								}, 1500);
							}
							
							
						}).catch(error => {
							if(error.response){

								
								
							}
						})
		},
		GRAB_KEY(){

			

			
			this.loading = true
			
			const fromData = {
						'email':this.email.toLowerCase(),
						'bitcoin':this.bitcoinaddress,
						'orderamount':this.orderamount
						
						
						
						

					}

				axios
					.post('/api/v1/GRAB_KEY/', fromData).then(response =>{
						
						
						//console.log(response.data)

						if (false){

							//this.GRAB_INVOICE(this.email.toLowerCase())
						}else{

							if (response.data == 'Please enter a valid email address.'){


								this.view = 'main'

								this.controlerror =  response.data

								

								var self = this;

								setTimeout(function() {
									self.controlerror = ''

									self.loading = false

								}, 2500);


							}else{
								this.grab_session(response.data.Session)
							}
						}

						

						




						
						
					}).catch(error => {
						if(error.response){

							
							
							
						}
					})

	

		},

		GRAB_TERMS(){

			if (this.email == '' || this.bitcoinaddress == '' || this.bitcoinaddress.length < 26){
				this.controlerror =  'Please make sure all fields are entered correctly.'

				var self = this;

								setTimeout(function() {
									self.controlerror = ''

								}, 2500);
			}else{
				this.view = 'terms'
			}

		},

		wealthlink(link){
			window.location.replace(this.links+'world/'+link)
		},

		linkup(){
			this.within = window.innerWidth
			this.hithin = window.innerHeight
		},

		goworld(link){
			//console.log(link)

			window.location.replace('https://unlimitedmoneygenerator.com/world/'+link)
		},

		REDIRECT(where){

			if (where == 'home'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/home')
			}

			if (where == 'economies'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/economies')
			}

			if (where == 'open'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/waitlist')
			}

			if (where == 'preorder'){
				window.location.replace('https://unlimitedmoneygenerator.com/preorder/gambling-x')
			}

			if (where == 'discover'){
				window.location.replace('https://unlimitedmoneygenerator.com/worlds')
			}

			if (where == 'social'){
				window.location.replace('https://unlimitedmoneygenerator.com/e2025/social')
			}

			if (where == 'login'){

				window.location.replace('https://unlimitedmoneygenerator.com/login')
				
			}
			},

		upper(){
			this.within = window.innerWidth
			this.hithin = window.innerHeight
		},

		GRAB_WORLDS_UPDATES(){
			if(this.showvieworld == true){

			

			
				const fromData = {
							'token':this.$store.state.token,
							'link':this.sync_worlds
							
							
							

						}

						axios
							.post('/api/v1/GETHISWORLD/', fromData).then(response =>{
								
								
								
								this.CHOOSE_WORLD = response.data

								this.FL = response.data.SERVERBIN.fl

								this.FOUND_W_MARK_CAP = response.data.MARKET_CAP

								
								
								this.showvieworld = true

								if (this.FL < 1000000){
									this.log = 'tr'
								}

								if (this.FL >= 1000000){
									this.log = 'tre'
								}

								if (this.FOUND_W_MARK_CAP < 1000000){
									this.logo= 'tr'
								}

								if (this.FOUND_W_MARK_CAP >= 1000000){
									this.logo = 'tre'
								}

								this.sync_worlds = link







								
								
							}).catch(error => {
								if(error.response){

									
									
									
								}
							})

						}
			
		},

		SELECT_CUSTOM_WORLD(link){
			if (true){
				window.location.replace('https://unlimitedmoneygenerator.com/world/'+link)
							}else{

			
			const fromData = {
						'token':this.$store.state.token,
						'link':link
						
						
						

					}

					axios
						.post('/api/v1/GETHISWORLD/', fromData).then(response =>{
							
							
							
							this.CHOOSE_WORLD = response.data

							this.FL = response.data.SERVERBIN.fl

							this.FOUND_W_MARK_CAP = response.data.MARKET_CAP

							console.log(this.CHOOSE_WORLD)

							this.showvieworld = true

							if (this.FL < 1000000){
								this.log = 'tr'
							}

							if (this.FL >= 1000000){
								this.log = 'tre'
							}

							if (this.FOUND_W_MARK_CAP < 1000000){
								this.logo= 'tr'
							}

							if (this.FOUND_W_MARK_CAP >= 1000000){
								this.logo = 'tre'
							}

							this.sync_worlds = link





						

							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})

					}
		},


		SELECT_LOTTERY_WORLD(link){
			
			if (true){
				window.location.replace('https://unlimitedmoneygenerator.com/world/'+link)
			}else{
			
			const fromData = {
						'token':this.$store.state.token,
						'link':link
						
						
						

					}

					axios
						.post('/api/v1/GETHISWORLD/', fromData).then(response =>{
							
							
							
							this.CHOOSE_WORLD = response.data

							this.FL = response.data.SERVERBIN.fl

							this.FOUND_W_MARK_CAP = response.data.MARKET_CAP

							console.log(this.CHOOSE_WORLD)

							this.showvieworld = true

							if (this.FL < 1000000){
								this.log = 'tr'
							}

							if (this.FL >= 1000000){
								this.log = 'tre'
							}

							if (this.FOUND_W_MARK_CAP < 1000000){
								this.logo= 'tr'
							}

							if (this.FOUND_W_MARK_CAP >= 1000000){
								this.logo = 'tre'
							}

							this.sync_worlds = link







							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})

					}
		},

		SELECT_GAMBLING_WORLD(link){
			if (true){
								
				window.location.replace('https://unlimitedmoneygenerator.com/world/'+link)
						
								//this.$router.push({path:'/world/'+link})
							}else{

			
			const fromData = {
						'token':this.$store.state.token,
						'link':link
						
						
						

					}

					axios
						.post('/api/v1/GETHISWORLD/', fromData).then(response =>{
							
							
							
							this.CHOOSE_WORLD = response.data

							this.FL = response.data.SERVERBIN.fl

							this.FOUND_W_MARK_CAP = response.data.MARKET_CAP

							console.log(this.CHOOSE_WORLD)

							this.showvieworld = true

							if (this.FL < 1000000){
								this.log = 'tr'
							}

							if (this.FL >= 1000000){
								this.log = 'tre'
							}

							if (this.FOUND_W_MARK_CAP < 1000000){
								this.logo= 'tr'
							}

							if (this.FOUND_W_MARK_CAP >= 1000000){
								this.logo = 'tre'
							}

							this.sync_worlds = link







							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
					}

		},
		SELECT_PVP_WORLD(link){
			
			if (true){
					window.location.replace('https://unlimitedmoneygenerator.com/world/'+link)
							}else{
			
			const fromData = {
						'token':this.$store.state.token,
						'link':link
						
						
						

					}

					axios
						.post('/api/v1/GETHISWORLD/', fromData).then(response =>{
							console.log('fire')
							
							console.log(response.data)
							this.CHOOSE_WORLD = response.data

							this.FL = response.data.SERVERBIN.fl

							this.FOUND_W_MARK_CAP = response.data.MARKET_CAP

							console.log(this.CHOOSE_WORLD)

							this.showvieworld = true

							if (this.FL < 1000000){
								this.log = 'tr'
							}

							if (this.FL >= 1000000){
								this.log = 'tre'
							}

							if (this.FOUND_W_MARK_CAP < 1000000){
								this.logo= 'tr'
							}

							if (this.FOUND_W_MARK_CAP >= 1000000){
								this.logo = 'tre'
							}

							this.sync_worlds = link







							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
					}

		},



		SELECT_MY_WORLD(link){
			if (true){
				window.location.replace('https://unlimitedmoneygenerator.com/world/'+link)
							}else{

			
			const fromData = {
						'token':this.$store.state.token,
						'link':link
						
						
						

					}

					axios
						.post('/api/v1/GETHISWORLD/', fromData).then(response =>{
							
							
							
							this.CHOOSE_WORLD = response.data

							this.FL = response.data.SERVERBIN.fl

							this.FOUND_W_MARK_CAP = response.data.MARKET_CAP

							console.log(this.CHOOSE_WORLD)

							this.showvieworld = true

							if (this.FL < 1000000){
								this.log = 'tr'
							}

							if (this.FL >= 1000000){
								this.log = 'tre'
							}

							if (this.FOUND_W_MARK_CAP < 1000000){
								this.logo= 'tr'
							}

							if (this.FOUND_W_MARK_CAP >= 1000000){
								this.logo = 'tre'
							}

							this.sync_worlds = link







							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})

					}
		},

		UPDATE(){
			const elementschumpe = document.getElementsByClassName('elementchump')

			const elementschange = document.getElementsByClassName('elementchange')
			for (let i = 0; i < this.MYWORLDSS.length; i++){
								
								
								
								

								
								


								this.marketcaphangar = this.MYWORLDSS[i].fl_marketcap/(this.MYWORLDSS[i].marketcap +1)
			
								//this.marketcaphangar = this.marketcaphangar * 100
								//console.log(this.marketcaphangar)

								elementschumpe[i].style.width = this.marketcaphangar + "%"

								elementschange[i].style.width = 100 - this.marketcaphangar + "%"

								//console.log(this.levelamount, "dde")
							
								//document.getElementById('chump').style.width = this.marketcaphangar + "%"
								

								///document.getElementById('change').style.width = 100 -this.marketcaphangar + "%"

								//console.log(elementschump[i].style.width, 'love')
								//elementschumpe[i].style.width = this.marketcaphangar + "%"

								//
							
		

						
						
							
						
						
							}
		},
		GRAB_WORLDS(){

			const fromData = {
						'token':this.$store.state.token,
						
						
						
						
						

					}

					axios
						.post('/api/v1/GRABWORLDS/', fromData).then(response =>{
							
							
							this.MYWORLDSS = response.data.OFFICIAL_WORLDS
							this.WEALTH = response.data.UNOFFICIAL_WORLDS
							//console.log(this.MYWORLDSS)
							this.OFFICIAL_LOTTERY_WORLDS = []

							this.OFFICIAL_GAMBLING_WORLDS = []

							this.OFFICIAL_TRADING_WORLDS = []

							this.PVP_WORLD = response.data.PVP_WORLDS
							var self = this;

							setTimeout(function() {
								self.UPDATE()
							}, 1500);
						
							
							

							
							
							

							

						

					}

						
					
					
					).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},
		

		getImage(imagePath) {
			return require(imagePath);

		},

		

		clear(){
			this.message = []
		},

		clears(){
			this.messages = []
		},

		GETCARDS(){
			const fromData = {
						'token':this.$store.state.token,
						'link':this.$route.params.id
						
						
						

					}

					axios
						.post('/api/v1/GETCARDS/', fromData).then(response =>{
							
							
							
							this.captureimage = 'http://127.0.0.1:8000/api/v1' +response.data.image
							//console.log(this.captureimage)
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},

		GETAPROFILE(){
			const fromData = {
						'token':this.$store.state.token,
						
						
						
						

					}

					axios
						.post('/api/v1/GETAPROFILE/', fromData).then(response =>{
							
							
							this.captureprofile = response.data
							this.pat = response.data.Bio
							//console.log(response.data)
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},

		CAPTURECARDSS(){

			const fromData = {
						'token':this.$store.state.token,
						'link':this.$route.params.id
						
						
						

					}

					axios
						.post('/api/v1/CAPTURECARDSG/', fromData).then(response =>{
							
							
							
							this.CAPTURECARDS = response.data
							
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},


		RESETPASS(){
			if(this.logconfirmpassword != this.logpassword){

				this.message = ['Both Passwords must match to continue']
				
			}else{
				const data = {
					'uid':this.$route.params.id,
					'token':this.$route.params.token,
					'new_password': this.logconfirmpassword
				}

				

				axios.post('api/v1/users/reset_password_confirm/',data)
					.then(response =>{
						//console.log(response.data)
						this.message = ['Successfully Reset Password']
						
						
						var self = this;
						
						setTimeout(function() {
							self.$router.push({ path: '/login' })
						}, 3000);



						
					})
					.catch(error =>{
						this.messages = error.response.data
						//console.log(this.message)
					})



			}
		}
		

		





		
		

	



	}




}

</script>

<style scoped>

#body {
            background-color: grey;
        }

h1 {
            color: whitesmoke;
        }

p {
            color: red;
        }

		#droppedclasss {
	width: 100%;
	height: 180%;
	right: 0%;
	top: 15%;
	position: relative;
	z-index: 20;
	background-color: rgb(9, 9, 10);
}

#widths{
	width:70%;
}

#left{
	margin-left:2.5%;
}
#grouperw{
	z-index: 500;
}
.note{
	pointer-events: none;
}
#not{pointer-events: none;}

#supporte{
	width:20%;
	background-color: rgb(18, 122, 34);
	margin-top:1%;
	margin-left:.5%;
	height:40%;
}
#scoob{
	line-height: 0%;;
	font-size:90%;
	margin-top:0.5%;
}
#button{
	width:10%;
	opacity: 0;
	height:50%;
	margin-top:1%;
	margin-left:.5%;
	border:2px solid rgb(1, 101, 1);
	color:rgba(0, 0, 0, 0.117);
	background-color: green;;
}

#buttoni{
	width:10%;
	opacity: 1;
	height:50%;
	margin-top:1%;
	margin-left:.5%;
	border:2px solid rgb(67, 3, 3);
	color:rgba(0, 0, 0, 0.117);
	background-color: rgb(92, 3, 3);;
}
#allaround{
	margin-left:0.5%;
	border:2px solid rgb(17, 17, 17);
	margin-top:1%;
	background-color: rgb(11, 11, 11);
}
#allaroundg{
	width:99%;
	margin-left:0.5%;
	border:2px solid rgb(3, 50, 7);
	margin-top:1%;
	opacity: 0;
	background-color: rgba(13, 108, 29, 0.203);
}
#goright{
	width:10%;
	right:0%;
	height:100%;
	right:0%;
	position: absolute;
	
}
#scossb{
	width:100%;
	margin-top:-30%;
}
#allaroundc{
	margin-left:0.5%;
	border:2px solid rgb(17, 17, 17);
	margin-top:1%;
	background-color: rgb(23, 102, 5);
	border:2px solid green;
	width:99%;
}
#orientation{
	width:100%;
	border:2px solid rgb(17, 17, 17);
	margin-left:.5%;
	line-height: 510%;
	opacity: 0;
	margin-top:-10%;
	background-color: rgb(11, 11, 11);
}
#barline{
	width:98%;
	font-size:30%;
	color:transparent;
	margin-left:.5%;
	background-color: rgb(162, 162, 162);
}
#barlined{
	width:98%;
	font-size:30%;
	color:transparent;
	margin-left:.5%;
	margin-top:1%;
	background-color: rgba(55, 55, 55, 0.363);
}

#barlineds{
	width:98%;
	font-size:30%;
	color:transparent;
	margin-left:.5%;
	margin-top:1%;
	background-color: rgba(13, 108, 29, 0.203);
}
#barlinedd{
	width:98%;
	font-size:30%;
	color:transparent;
	margin-left:.5%;
	margin-top:0%;
	background-color: rgba(55, 55, 55, 0.363);
}
#barlineddd{
	width:68%;
	font-size:30%;
	color:transparent;
	margin-left:.5%;
	margin-top:0%;
	background-color: rgba(55, 55, 55, 0.363);
}
#barlinedr{
	width:98%;
	font-size:50%;
	color:transparent;
	margin-left:.5%;
	margin-top:1%;
	background-color: rgba(101, 11, 11, 0.363);
}
#mount{
	background-color: rgba(23, 23, 23, 0.572);;
	width:99%;
	height:10%;
	opacity: 0;
	margin-left:.5%;
	margin-top:2%;
	position:relative;
}
#ted{
	width:98%;
	margin-top:1%;
	margin-left:1%;
}
#banner{
	width:98%;
	height:60%;
	margin-top:1%;
	margin-left:0.5%;
	background-color: rgba(22, 22, 22, 0.391);
}
#skull{
	width:100%;
	margin-top:-13%;
}

#skullr{
	width:20%;
		background-color: rgb(114, 9, 9);
	margin-top:1%;
	margin-left:.5%;
	border:1px solid rgb(75, 4, 4);
	
}
#skullrr{
	width:20%;
	background-color: rgb(114, 9, 9);
	margin-top:0%;
	margin-left:.5%;
	border:1px solid rgb(75, 4, 4);
	
}

#mark{
	width:50%;
}

#marks{
	width:70%;
}
#event{
	margin-top:1%;
	width:60%;
	margin-left:.5%;
	background-color: transparent;	
}

#eventright{
	margin-top:1%;
	position: absolute;

	right:0%;
	width:37.5%;
	margin-left:.5%;
	top:0%;

}
#mover{
	margin-top:1%;
}
#paymentinfo{

	height:60%;
	width:80%;
	margin-top:0%;
	position: relative;
	margin-left:1%;
	border:2px solid rgb(19, 19, 19);
	background-color:rgb(6, 6, 6);
}
#der{
	color:rgba(62, 62, 62, 0.224);
}
#glow{
	width:10%;
	color:rgba(62, 62, 62, 0.063);
	margin-top:1%;
	margin-left:1%;

}
#payoutbox{
	margin-top:2%;
	margin-left:1%;
	width:110%;
	border:2px solid rgb(24, 24, 24);
	background-color: rgb(17, 17, 17);
}

#mountup{
	width:98%;
	height:10%;
	margin-left:1%;
	border:2px solid rgb(15, 15, 18);
	margin-top:2%;
	position:relative;
	background-color: rgb(10, 10, 11);;

	filter: drop-shadow(0px 0px 1px rgb(86, 85, 101))


}
#mountupd{
	width:98%;
	height:10%;
	margin-left:1%;
	border:2px solid rgb(25, 25, 25);
	margin-top:2%;
	position:relative;
	background-color: rgb(22, 22, 22);;
}
#mountupinvoice{
	width:45%;
	height:10%;
	margin-left:1%;
	border:2px solid rgb(25, 25, 25);
	margin-top:2%;
	color:rgba(19, 19, 19, 0.169);
	position:relative;
	background-color: rgba(17, 17, 17, 0.635);;
}
#mountupinvoicea{
	width:98%;
	height:10%;
	margin-left:1%;
	border:2px solid rgb(25, 25, 25);
	margin-top:2%;
	position:relative;
	background-color: rgb(9, 9, 9);;
}
#mountupt{
	width:99%;
	height:10%;
	margin-left:1%;
	border:2px solid rgb(36, 36, 36);
	margin-top:2%;
	position:relative;
	background-color: rgb(15, 15, 15);;
}
#leftbox{
	width:30%;
	top:0%;

	position:absolute;
	height:10%;
}


#rightbox{
	width:50%;
	top:50%;
	right:0%;
	position:absolute;
	height:10%;
}
#height{

	font-size: 1000%;;
	line-height: 150%;;
}

#heightdown{

font-size: 500%;;
line-height: 150%;;
}

#heightdownd{
width:100%;
font-size: 500%;;
line-height: 150%;;
}
#down{
	font-size:90%;
}
#black{
	color:black;
}

#blackl{
	font-size:90%;
	color:black;
}
#red{
	color:rgb(155, 11, 11);
}

#redr{
	margin-top:1%;
	font-weight: bold;
	color:rgb(155, 11, 11);
}
#USD{
	color:green;
}
#justwhite{
	color:rgb(220, 220, 220);
}
#scoss{
	width:100%;
}


#scosf{
	position: absolute;
	width:100%;
	margin-top:-20%;
}
#scosef{
	opacity: 0.05;
	position: absolute;
	width:100%;
	margin-top:-20%;
}
#scoseff{
	opacity: 0.15;
	position: absolute;
	width:100%;
	margin-top:-20%;
}

#scosff{
	position: absolute;
	width:30%;
	top:0%;
	right:1%;
	margin-top:0%;
}
#scosss{
	width:25%;
}
#droppedclassx {
	width: 100%;
	height: 100%;
	right: 0%;
	top: 00%;
	position: absolute;
	z-index: 100;
	background-color: rgb(9, 9, 10);
}
#droppedclassxx {
	width: 100%;
	height: 100%;
	right: 0%;
	margin-top: 10%;
	position: relative;
	z-index: 100;
	background-color: rgb(9, 9, 10);
}
#stride{
	width:60%;
	margin-left:1%;
	background-color: rgb(16, 16, 16);;
	border:2px solid rgb(20, 20, 20);
}
#qrcode{
	line-height: 10%;
	height:60%;
	width:20%;
	margin-top:1%;
	position: relative;
	margin-left:1%;
}
#qr{
	width:100%;
}
		#droppedclass {
	width: 100%;
	height: 180%;
	right: 0%;
	top: 17%;
	position: relative;
	z-index: 500;
	background-color: rgb(9, 9, 10);
}
#fruit{
	top:0%;
	right:0%;
	width:50%;
	height:50%;
	color:black;
	text-align: right;
	position: absolute;
}
#drag{
	width:100%;
	margin-top:-12%;
}
#margin{
	color:rgb(220, 220, 220);
	margin-left:3.5%;
}
#greense{
	font-weight: bold;
	color:rgb(20,120,20);
	
}
#pvpadjust{
	width:17%;
	border:2px solid rgba(176, 199, 200, 0.457);
	margin-left:1%;
	margin-top:1%;
}

#wealthadjust{
	width:57%;
	border:2px solid rgba(176, 199, 200, 0.457);
	margin-left:1%;
	margin-top:1%;
	color:rgba(41, 41, 41, 0.144);
}
#bold{

}

#bolder{
	text-align: center;
font-weight: bold;
}
#scos{
	width:120%;
	margin-top:0%;
}
#margins{
	margin-left:5%;

}
#economycontainer{
	width:70%;
	height:40%;
	top:50%;
	right:0%;
	text-align: right;
	color:black;
	position: absolute;
}

#economyinfocontainer{
	width:70%;
	height:10%;
	top:0%;
	right:0%;
	text-align: right;
	color:black;
	position: absolute;
	
}
#grouphorizontal{
	display: flex;
	margin-left:-50%;
	margin-left:0%;
}
#change{
	width:95%;
	left: 2.5%;
	right:0%;
	color:transparent;
	line-height: 100%;
	top:0%;
	height:10%;
	background-color: rgb(18, 172, 59);
}
#chump{
	width:15%;
	left: 1.5%;
	right:0%;
	color:transparent;
	line-height: 100%;
	top:0%;
	height:10%;
	background-color: rgb(221, 145, 30);
}



#grouperflex{
	width: 80%;
	margin-top:1%;

	display: flex;
}


#widebody{
	width:60%;
	height:95%;
	margin-left:5%;
	color:rgba(65, 65, 65, 0.286);
	margin-left:.5%;
	margin-top:.5%;

}
#white{
	color:rgb(176, 176, 176);
}

#whiterb{
	width:70%;
	color:rgb(176, 176, 176);
}
#green{
	color:rgb(28, 170, 45);
}

#greendark{
	font-size:120%;
	color:rgb(11, 136, 26);
}

#greendarkd{
	margin-top:1%;
	color:rgb(11, 136, 26);
}
#greend{
	margin-top:2%;
	font-weight: bold;
	color:rgb(28, 170, 45);
}
#greendd{
	margin-top:2%;
	font-weight: bold;
	color:green;
}

#greenddd{
	margin-top:0%;
	font-weight: bold;
	color:green;
}



#whiter{
	color:rgb(188, 188, 188);
}


#whitertt{
	line-height: 300%;;
	color:rgb(188, 188, 188);
}


#whiterbox{
	width:50%;
	margin-left:1%;
	background-color: rgb(27, 27, 27);
	color:rgb(188, 188, 188);
	
}
#whiterboxs{
	width:40%;
	margin-left:1%;
	background-color: rgba(12, 12, 12, 0.778);
	color:rgb(188, 188, 188);
	
}



#whiterboxe{
	width:50%;
	margin-left:1%;
	background-color: rgba(12, 12, 12, 0.778);
	color:green;
	
}

#whiterboxee{
	width:50%;
	margin-left:1%;
	background-color: rgba(12, 12, 12, 0.778);
	color:green;
	
}
#whiterr{
	width:70%;
	color:rgb(188, 188, 188);
	text-align: right;

	
}

#whiterrg{
	color:green;
	text-align: right;
	
}
#whited{
	margin-top:1%;
	color:rgb(188, 188, 188);
}
#marginse{
	margin-left:5%;
	color:rgb(188, 188, 188);
}

#whiterb{
	font-weight:bold;
	color:rgb(188, 188, 188);
}

#whiterd{
margin-top:1%;
	color:rgb(188, 188, 188);
}
#whiterbd{
	margin-top:1%;
	font-weight:bold;
	color:rgb(188, 188, 188);
}
#widebodydesc{
	width:50%;
	height:95%;
	margin-left:5%;
	color:rgba(34, 34, 34, 0.649);
	margin-left:.5%;
	margin-top:.5%;
	background-color: rgba(0, 0, 0, 0.586);

}
#godown{
	margin-top:1%;
	font-weight: bold;;
}

#godowngreen{
	color:green;
	margin-top:1%;
	font-weight: bold;;
}
#godowni{
	color:transparent;
	font-size:20%;
	width:40%;

	font-weight: bold;;
}
#godownw{
	margin-top:1%;
	font-weight: bold;;
	color:white;
}

#godownr{
	margin-top:1%;
	color:rgb(120, 13, 13);
	font-weight: bold;;
}


#godownrr{
	margin-top:1%;
	color:rgb(150, 17, 17);
	font-weight: bold;;
}
#godownm{
	margin-top:15%;
	font-weight: bold;;
}

#godownmm{
	color:transparent;
	margin-top:15%;
	font-weight: bold;;
}
#godownline{
	margin-top:1%;
	font-weight: bold;;
}
#topcontainer{
	width: 100%;

	top:0%;
	color:rgb(27, 159, 53);
	background-color: rgb(10, 10, 12);
	position: relative;
}
#grouper{
	width:99%;
display: flex;


	
}
#grouperd{
	width:99.5%;
display: flex;
margin-top:1%;


	
}
#grouperw{
	width:80%;
	display: flex;
}

#groupertp{
	width: 98%;
 

	margin-top: 0%;
	margin-left:0.5%;
	position: relative;

	display: flex;
}

#groupertpp{
	width: 98%;
	height:100%;

	margin-top: 0%;
	margin-left:0.5%;
	position: relative;

	display: flex;
}
#grouperah{
	width:100%;
	height:15%;
	z-index: 500;
	position: relative;
	display: flex;
}

#groupery{
	width: 99%;
    margin-top:2%;

	position: relative;

	display: flex;
}


#grouperyy{
	width: 100%;
    margin-top:0%;
	
	position: relative;

	display: flex;
}
#grouperyyy{
	width: 100%;
    margin-top:1%;
	
	position: relative;

	display: flex;
}
#grouperc{
	width: 99%;
   
	height:47.5%;
	margin-top: 2%;
	margin-left:0.5%;
	position: relative;

	display: flex;
}
#downcontainer{
	width: 100%;
	height: 20%;
	background-color: rgb(10, 10, 12);
	position: relative;
}
#torightb{
	font-weight: bold;
}

#placebox{
	width: 90%;
	position: relative;
	top:50%;
	height:50%;
	left:0%;
	
    
}

#placeboxlarge{
	width: 99%;
	position: absolute;
	top:75.5%;
	pointer-events: none;
	height:20%;
	left:.5%;
	background-color: rgba(21, 21, 21, 0.904);
	
    
}

#placeboxlargecheck{
	width: 90%;
	position: absolute;
	top:2.5%;

	height:22%;
	left:.5%;
	background-color: rgba(21, 21, 21, 0.904);
	
    
}

#placeboxlargeorder{
	width: 90%;
	position: absolute;
	top:32.5%;
	border:solid 2px rgb(17, 17, 17);
	height:32%;
	left:.5%;
	background-color: rgba(21, 21, 21, 0.904);
	
    
}
#usd{
	width:80%;
	margin-top:1%;
	height:32.5%;
	color:rgb(188, 188, 188);
	margin-left:1%;
}
#left{
	margin-top:0.5%;
	color:rgb(188, 188, 188);
	
}
#usdi{
	width:80%;
	margin-top:1%;
	height:12.5%;
	color:rgb(188, 188, 188);
	margin-left:1%;
	background-color: black;
}
#usdr{
	width:67.5%;
	position: absolute;
	margin-top:0.75%;
	height:52.5%;
	background-color: black;
	color:rgb(188, 188, 188);
	margin-left:1%;
}

#usder{
	width:80%;
	margin-top:2%;
	height:32.5%;
	color:rgb(188, 188, 188);
	margin-left:1%;
	background-color: black;
}
#usderi{
	width:80%;
	margin-top:2%;
	height:32.5%;
	color:rgb(188, 188, 188);
	margin-left:1%;

}
#usds{
	width:80%;
	height:32.5%;
	color:rgb(215, 215, 215);
	margin-left:1%;
	margin-top:2%;
	pointer-events: none;
	background-color: rgb(0, 0, 0);
}
#payoutsbbbtt{
	width:20%;
	
	height:30%;
	margin-top:2%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}
#payoutsb{
	width:30%;
	
	
	margin-left:5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}


#payoutsbbt{
	width:50%;
	margin-top:5%;
	
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
	border:2px solid rgb(15, 84, 15);
}

#payoutsbbtc{
	width:15%;
	margin-top:0.75%;
	
	margin-left:71%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(18, 151, 42);
	border:2px solid rgb(5, 48, 6);
}
#saint{
	width:100%;
	background-color: rgb(21, 21, 21);
	margin-left:2%;
	border:2px solid rgb(10, 10, 10);
	
}

#cash{	
	width:90%;
	background-color: rgb(16, 121, 22);
	margin-left:2%;
	margin-top:1%;
	border:2px solid rgb(12, 83, 8);

}
#cashr{	
	width:90%;
	background-color: rgb(16, 121, 22);
	margin-left:2%;
	margin-top:2%;
	border:2px solid rgb(12, 83, 8);

}
#luck{	
	width:15%;
	background-color: rgb(16, 121, 22);
	margin-left:2%;
	margin-top:1%;
	border:2px solid rgb(12, 83, 8);

}

#badluck{	
	width:90%;
	background-color: rgb(111, 10, 10);
	margin-left:2%;
	margin-top:1%;
	border:2px solid rgb(75, 6, 6);

}
#cashavailable{	
	width:1%;
	background-color: rgb(111, 10, 10);
	margin-left:2%;
	margin-top:1%;
	border:2px solid rgb(75, 6, 6);

}
#saintsrow{
	width:60%;
	height:100%;

	position: absolute;
	background-color: rgb(36, 36, 36);
	right:0%;
	
}

#saintsrowl{
	width:35%;
	height:100%;

	position: absolute;
	background-color: rgb(36, 36, 36);
	left:0%;
	
}


#saintsrowle{
	width:90%;
	height:100%;
	margin-top:1%;
	margin-left:1%;
	background-color: rgb(5, 137, 38);
	left:0%;
	
}



#saintsrowled{
	width:1%;
	background-color: rgb(5, 113, 32);
	height:100%;
	margin-top:1%;
	margin-left:1%;

	left:0%;
	
}
#payoutsbbtt{
	width:15%;
	
	border:2px solid rgb(15, 84, 15);
	margin-top:-4.75%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}

#payoutsbbttc{
	width:25%;
	
	border:2px solid rgb(85, 6, 6);
	margin-top:.75%;
	margin-left:50.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(114, 9, 9);
}
#payoutsbbttrr{
	width:15%;
	
	border:2px solid rgb(36, 1, 1);
	margin-top:.75%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(122, 6, 6);
}
#payoutsbbttr{
	width:20%;
	
	border:2px solid rgb(36, 1, 1);
	margin-top:1.5%;
	margin-left:1.5%;
	color:rgba(42, 3, 3, 0.263);;
	background-color: rgb(122, 6, 6);
}
#payoutsbb{
	width:30%;
	
	margin-top:2.5%;
	margin-left:2.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}
#chart{
	color:rgb(212, 212, 212);;
}
#with{
	width:80%;
	margin-left:1%;
	color:rgb(194, 194, 194);
}

#withlong{
	width:100%;
	margin-left:1%;
	margin-top:1%;
	color:rgb(194, 194, 194);
}
#withlongs{
	width:40%;
	margin-left:1%;
	margin-top:1%;
	color:rgb(194, 194, 194);
}

#all{
	height:100%;
}

#withlongs{
	width:40%;
	margin-left:1%;
	visibility: hidden;
	margin-top:1%;
	color:rgb(194, 194, 194);
}
#usd{
	background-color: black;

}

@media only screen and (max-width:1400px) and (max-height:1025px){
	#heightdown{
		font-size:400%;
	}
	#scosss{
	width:35%;
}
#skull{
	width:100%;
	margin-top:-13.5%;
}

#scoob{
	line-height: 0%;;
	font-size:90%;
	margin-top:0.5%;
	color:rgb(5, 112, 5);
	
}
#height{

font-size: 900%;;
line-height: 150%;;
}
#cashr{	
	width:90%;
	background-color: rgb(16, 121, 22);
	margin-left:2%;
	margin-top:3%;
	border:2px solid rgb(12, 83, 8);

}

#redr{
	margin-top:1%;
	font-size:120%;
	font-weight: bold;
	color:rgb(155, 11, 11);
}

#red{
	font-size:130%;
	color:rgb(155, 11, 11);
}	

#skullrr{
	width:25%;
	background-color: rgb(114, 9, 9);
	margin-top:0%;
	margin-left:1%;
	border:1px solid rgb(75, 4, 4);
	
}

#scale{
	font-size:120%;
	font-weight: bold;
}
#godowngreen{
	color:green;
	font-size:150%;
	margin-top:1%;
	line-height: 200%;
	font-weight: bold;;
}

#button{
	width:15%;
}

#blackl{
	font-size:90%;
	color:black;
	margin-top:-2%;
}
}


@media only screen and (max-width:1025px) and (max-height:1367px){

#button{
	width:20%;
}

#blackl{
	font-size:90%;
	color:black;
	margin-top:-2%;
}
#saintsrowle{
	width:90%;
	height:100%;
	margin-top:1.5%;
	margin-left:3%;
	background-color: rgb(5, 137, 38);
	left:0%;
	
}


#scoss{
	width:115%;
}
#red{
	font-size:150%;
	color:rgb(155, 11, 11);
}
#scale{
	font-size:120%;
	font-weight: bold;
}
#scosss{
	width:45%;
}
#skullrr{
	width:40%;
	background-color: rgb(114, 9, 9);
	margin-top:0%;
	margin-left:1%;
	border:1px solid rgb(75, 4, 4);
	
}
#mountupinvoice{
	width:65%;
	height:10%;
	margin-left:1%;
	border:2px solid rgb(25, 25, 25);
	margin-top:2%;
	color:rgba(19, 19, 19, 0.169);
	position:relative;
	background-color: rgba(17, 17, 17, 0.635);;
}

#redr{
	margin-top:1%;
	font-size:120%;
	font-weight: bold;
	color:rgb(155, 11, 11);
}
#godownrr{
	font-size:110%;
	margin-top:1%;
	color:rgb(150, 17, 17);
	font-weight: bold;;
}
#bolder{
	font-size:110%;
}
#godown{
	font-size:110%;
}
#skullr{
	width:30%;
	background-color: rgb(114, 9, 9);
	margin-top:1%;
	margin-left:.5%;
	border:1px solid rgb(75, 4, 4);
	
}
#payoutbox{
	margin-top:2%;
	margin-left:1%;
	width:110%;
	border:2px solid rgb(24, 24, 24);
	background-color: rgb(17, 17, 17);
}


#height{

font-size: 600%;;
line-height: 150%;;
}
#heightdownd{
width:100%;
font-size: 400%;;
line-height: 150%;;
}
#heightdown{
		font-size:320%;
	}
	#cashr{	
	width:3120%;
	background-color: rgb(16, 121, 22);
	margin-left:2%;
	margin-top:4%;
	border:2px solid rgb(12, 83, 8);

}
#supporte{
	width:30%;
	background-color: rgb(18, 122, 34);
	margin-top:1%;
	margin-left:.5%;
	height:50%;
	font-size:320%;
}
#goright{
	width:12%;
	right:0%;
	top:20%;
	height:100%;
	right:0%;
	position: absolute;
	
}
#usdr{
	width:67.5%;
	position: absolute;
	margin-top:0.75%;
	height:52.5%;
	background-color: black;
	color:rgb(188, 188, 188);
	margin-left:1%;
}

#payoutsbbtc{
	width:25%;
	margin-top:0.75%;
	
	margin-left:61%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(18, 151, 42);
	border:2px solid rgb(5, 48, 6);
}
}


@media only screen and (max-width:932px){
	#skull{
	width:100%;
	margin-top:-4%;
}
#qrcode{
	line-height: 10%;
	height:60%;
	width:40%;
	margin-top:1%;
	position: relative;
	margin-left:1%;
}
#height{
margin-left:0%;
font-size: 500%;;
margin-top:2.5%;
line-height: 150%;;
}
#rightbox{
	width:50%;
	top:5%;
	right:0%;
	position:absolute;
	height:10%;
}
#payoutsbbtt{
	width:25%;
	
	border:2px solid rgb(15, 84, 15);
	margin-top:-10.75%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}
#cashr{	
	width:100%;
	background-color: rgb(16, 121, 22);
	margin-left:2%;
	margin-top:3%;
	border:2px solid rgb(12, 83, 8);

}
#saintsrowle{
	width:90%;
	height:100%;
	margin-top:1.75%;
	margin-left:3%;
	background-color: rgb(5, 137, 38);
	left:0%;
	
}

#saint{
	width:140%;
	background-color: rgb(21, 21, 21);
	margin-left:2%;
	border:2px solid rgb(10, 10, 10);
	
}
#payoutbox{
	margin-top:2%;
	margin-left:1%;
	width:130%;
	border:2px solid rgb(24, 24, 24);
	background-color: rgb(17, 17, 17);
}

#scosf{
	position: absolute;
	width:110%;
	margin-top:-20%;
}
#heightdown{
	font-size: 300%;
}
}


@media only screen and (max-width:700px) and (max-height:400px) {

	#qrcode{
		width:40%;
	}
	#mark{
	width:60%;
	margin-top:3%;
}

#saint{
	width:200%;
	background-color: rgb(21, 21, 21);
	margin-left:2%;
	border:2px solid rgb(10, 10, 10);
	
}
#scoss{
	width:120%;
}
#width{
	width:120%;
}



#height{
margin-left:0%;
font-size: 400%;;
margin-top:2.5%;
line-height: 150%;;
}
#heightdownd{
width:100%;
font-size: 300%;;
line-height: 150%;;
}
#saintsrowle{
	width:90%;
	height:100%;
	margin-top:3%;
	margin-left:2%;
	background-color: rgb(5, 137, 38);
	left:0%;
	
}

#skull{
	width:110%;
	margin-top:0%;
}

#button{
	width:50%;
}


}


@media only screen and (max-width:500px) {
	#scoss{
	width:200%;
}
#left{
	margin-top:5.5%;
}
#widths{
	width:70%;
}
#qrcode{
	line-height: 10%;
	height:60%;
	width:80%;
	margin-top:1%;
	position: relative;
	margin-left:2.5%;
}
#mountupinvoice{
	width:95%;
	height:10%;
	margin-left:2.5%;
	border:2px solid rgb(25, 25, 25);
	margin-top:2%;
	color:rgba(19, 19, 19, 0.169);
	position:relative;
	background-color: rgba(17, 17, 17, 0.635);;
}
#scosss{
	width:105%;
}
#supporte{
	width:60%;
	background-color: rgb(18, 122, 34);
	margin-top:1%;
	margin-left:.5%;
	height:40%;
}
#skullrr{
	width:60%;
	background-color: rgb(114, 9, 9);
	margin-top:0%;
	margin-left:1%;
	border:1px solid rgb(75, 4, 4);
	
}

#paymentinfo{

height:60%;
width:96%;
margin-top:2%;
position: relative;
margin-left:2%;
border:2px solid rgb(19, 19, 19);
background-color:rgb(6, 6, 6);
}
#scoob{
	line-height: 0%;;
	font-size:90%;
	margin-top:1%;
}
#payoutsbbtt{
	width:45%;
	
	border:2px solid rgb(15, 84, 15);
	margin-top:2.5%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}
#payoutsbbttrr{
	width:45%;
	
	border:2px solid rgb(36, 1, 1);
	margin-top:.75%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(122, 6, 6);
}
#barlinedr{
	width:98%;
	font-size:50%;
	color:transparent;
	margin-left:1%;
	margin-top:1%;
	background-color: rgba(101, 11, 11, 0.363);
}
#scossb{
	width:170%;
	margin-left:-35%;
	margin-top:-10%;
}
#skull{
	width:100%;
	margin-top:0%;
}

#payoutsbbtc{
	width:35%;
	margin-top:0.75%;
	
	margin-left:51%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(18, 151, 42);
	border:2px solid rgb(5, 48, 6);
}
#heightdown{

font-size: 200%;;
line-height: 100%;;
position: relative;
z-index: 100;
margin-top:5%;

}
#event{
	margin-top:1%;
	width:99%;

	margin-left:.5%;
	
}
#grouperw{
	width:100%;
	display: flex;
	z-index:100;
}
#eventright{
	margin-top:1%;
	position: relative;
	background-color: red;
	right:0%;
	width:100%;
	margin-left:.5%;
	top:0%;

}
#skullr{
	width:60%;
	background-color: rgb(114, 9, 9);
	margin-top:1%;
	margin-left:2%;
	border:1px solid rgb(75, 4, 4);
	
}
#leftbox{
	width:70%;
	top:0%;

	position:absolute;
	height:10%;
}
#button{
	width:32.5%;
}
#saintsrowle{
	width:90%;
	height:100%;
	line-height: 200%;
	font-size:500%;	
	margin-top:1.5%;
	margin-left:2%;
	background-color: rgb(5, 137, 38);
	left:0%;
	padding-top:5px;
	
}

#width{
	width:220%;
}
#saint{
	width:130%;
	background-color: rgb(21, 21, 21);
	margin-left:5%;
	border:2px solid rgb(10, 10, 10);
	
}
#height{
	font-size:300%;
}
#rightbox{
	width:100%;
	top:0%;
	margin-top:10%;
	right:0%;
	position:relative;
	height:50%;

}
#heightdownd{
width:200%;
font-size: 300%;;
line-height: 150%;;
}
#payoutbox{
	margin-top:2%;
	margin-left:3%;
	width:135%;
	border:2px solid rgb(24, 24, 24);
	background-color: rgb(17, 17, 17);
}
#grouper{
	width:100%;
display: flex;


	
}

#width{
	margin-top:5%;
	width:120%;
}
#scoob{
	line-height: 100%;;
	font-size:90%;
	margin-top:0.5%;
	color:rgb(5, 112, 5);
	
}
}

@media only screen and (max-width:400px){
	#scoss{
	width:250%;
}
#payoutsbbtt{
	width:45%;
	
	border:2px solid rgb(15, 84, 15);
	margin-top:2.5%;
	margin-left:1.5%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: green;
}
#usdr{
	width:48.5%;
	position: absolute;
	margin-top:0.75%;
	height:52.5%;
	background-color: black;
	color:rgb(188, 188, 188);
	margin-left:1%;
}
#skull{
	width:100%;
	margin-top:-1%;
}

#scoob{
	line-height: 100%;;
	font-size:90%;
	margin-top:0.5%;
	color:rgb(5, 112, 5);
	
}

#grouperw{
	width:100%;
	margin-left:0%;
	z-index:100;
}

}

@media only screen and (min-width:1700px){
	#godown{
	margin-top:1%;
	font-size:130%;
	font-weight: bold;;
}#skull{
	width:100%;
	margin-top:-15%;
}
#paymentinfo{

height:60%;
width:90%;
margin-top:0%;
position: relative;
margin-left:1%;
border:2px solid rgb(19, 19, 19);
background-color:rgb(6, 6, 6);
}
#scosff{
	position: absolute;
	width:30%;
	top:0%;
	right:1%;
	margin-top:0%;
}
#barline{
	width:98%;
	font-size:40%;
	color:transparent;
	margin-left:.5%;
	margin-top:.5%;
	background-color: rgb(162, 162, 162);
}
#scale{
	font-size:130%;
}
#goright{
	width:10%;
	right:0%;
	height:100%;
	right:0%;
	top:15%;
	position: absolute;
	
}
#payoutsbbtc{
	width:15%;
	margin-top:1.75%;
	
	margin-left:71%;
	color:rgba(79, 79, 79, 0.263);;
	background-color: rgb(18, 151, 42);
	border:2px solid rgb(5, 48, 6);
}
#usdr{
	width:67.5%;
	position: absolute;
	margin-top:1.75%;
	height:52.5%;
	background-color: black;
	color:rgb(188, 188, 188);
	margin-left:1%;
}
#scoob{
	line-height: 0%;;
	font-size:130%;
	margin-top:1%;
}
#grouperw{
	width:80%;
padding-top:5px;
	display: flex;
}

#scaler{
	font-size:150%;

}
#white{
	font-size:120%;
	color:rgb(176, 176, 176);
	line-height: 200%;
}

#whiter{
	font-size:120%;
	line-height: 200%;
}

#whiterbd{
	font-size:120%;
	line-height: 200%;
}
#whiterd{
	font-size:120%;
	line-height: 200%;
}
#scosss{
	width:25%;
}
#qrcode{
	line-height: 10%;
	height:60%;
	width:15%;
	margin-top:1%;
	position: relative;
	margin-left:1%;
}
#godownrr{
	font-size:120%;
}


#godownr{
	font-size:140%;
}
#red{
	font-size:140%;
	color:rgb(155, 11, 11);
}

#redr{
	font-size:120%;
	margin-top:1%;
	font-weight: bold;
	color:rgb(155, 11, 11);
}
#godowngreen{
	color:green;
	margin-top:1%;
	font-size:150%;
	line-height: 200%;
	font-weight: bold;;
}
#blackl{
	font-size:120%;
	margin-top:-2%;
	color:black;
}
#mark{
	width:40%;
	margin-top:-2%;
}
}
</style>taytondavis01@gmail.com
