import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/worldstwo.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
 

  {
    path: '/terms',
    name: 'terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/terms.vue')
  },
  {
    path: '/moreinfo',
    name: 'moreinformation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/moreinfo.vue')
  },
  {
    path: '/withdrawals',
    name: 'withdrawals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/withdrawals.vue')
  },


  {
    path: '/home',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/worlds.vue')
  },

  {
    path: '/payouts',
    name: 'worlds',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/worldstwo.vue')
  },

  {
    path: '/community',
    name: 'community',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/community.vue')
  },




  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes


})

export default router
